const testimonialsSliders = async function( $block ) {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Navigation } = await import( "swiper/modules" );

	const $sliderText = $block.find( ".testimonials-text-slider" );
	const $sliderVideo = $block.find( ".testimonials-video-slider" );

	if ( $sliderText.length ) {
		const testimonialsTextSlider = new Swiper( $sliderText[0], {
			modules: [Navigation],
			slidesPerView: 1,
			loop: true,
			speed: 1000,
			navigation: {
				prevEl: ".testimonials__text--prev",
				nextEl: ".testimonials__text--next",
			},
		} );

		testimonialsTextSlider.init();
	}

	if ( $sliderVideo.length ) {
		const testimonialsVideoSlider = new Swiper( $sliderVideo[0], {
			modules: [Navigation],
			slidesPerView: 1,
			loop: true,
			speed: 1000,
			navigation: {
				prevEl: ".testimonials__video--prev",
				nextEl: ".testimonials__video--next",
			},
		} );

		testimonialsVideoSlider.init();

	}
};
 
themeUtils.loadBlock( testimonialsSliders, "testimonials", ".testimonials" );